<template>
  <div class="p-setting-logoff has-header-nav">
    <post-header
      :menu-status="false"
      :auto-back="true"
      class="logoff-header"
    >
      社区账号注销提示
    </post-header>
    <div
      v-report:exposure="{ operid: '1627000010206' }"
      class="logoff-main"
    >
      <p style="margin-top: 9px;">
        亲爱的用户，闪闪对您的注销深表遗憾。为保证您的账号和财产安全，在您提交的注销申请生效前，<strong>请您确保已满足以下条件</strong>，闪现一下团队也有权视情况进行验证：
      </p>
      <h4>1. 账号处于安全及正常使用状态</h4>
      <p style="text-indent: 18px;">
        账号为正常使用无被盗等风险。账号未处于禁言期间；
      </p>
      <h4>2. 收益已结清或得到妥善处理</h4>
      <p style="text-indent: 18px;">
        包括但不限于社区积分、道具、圈主收益、答主收益、等级权益等收益。请您妥善处理，若未处理，视为您自愿放弃所有收益；
      </p>
      <h4>3. 账号无任何纠纷</h4>
      <p style="text-indent: 18px;">
        包括投诉举报、被投诉举报、仲裁、诉讼等纠纷。
      </p>
    </div>
    <div class="next-box">
      <div class="select-field">
        <div
          :class="{active: isActive}"
          @click="toggleSelect"
          class="selected"
        />
        <p>我已详细阅读并满足账号注销条件</p>
      </div>
      <Button
        :type="isActive ? 'primary' : 'default'"
        :disabled="!isActive"
        @click="next"
        size="xl"
        style="padding: 7px;"
      >
        下一步
      </Button>
      <Toast v-show="showToast">
        {{ toastText }}
      </Toast>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user';
import Button from '@/ui/button';
import Toast from '@/ui/toast/index.vue';
import { logger } from '@/util/logger';
import reportApi from '@/api/report';
import PostHeader from '@/component/post-header/index.vue';

export default {
  name: 'Logoff',
  components: {
    Button,
    Toast,
    PostHeader,
  },
  data() {
    return {
      isActive: false,
      showToast: false,
      toastText: '',
    };
  },
  methods: {
    toggleSelect() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        reportApi({ operid: '1627000010306' }, '账号注销提示页面“下一步”点击');
      }
    },
    toast(text) {
      this.toastText = text;
      this.showToast = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.showToast = false;
          resolve();
        }, 1500);
      });
    },
    next() {
      reportApi({ operid: '1627000010307' }, '账号注销提示页面“下一步”点击');
      userApi.checkCloseAccount().then((res) => {
        if (res.ret === 0) {
          this.$router.replace('/setting/logoff-protocol?_gcwv=2');
        } else {
          this.$router.replace('/setting/logoff-error?_gcwv=2');
        }
      })
        .catch((e) => {
          if (e?.data?.ret === 21032) {
            this.$router.replace('/setting/logoff-error?_gcwv=2');
            return;
          }
          if (e?.data?.ret === 10000) {
            this.toast('登录态异常，请稍候重试');
            return;
          }
          this.toast('系统繁忙，请稍候重试');
          logger.error('检查注销条件（checkCloseAccount）发生异常', e);
        });
    },
  },
};
</script>
<style lang="scss" src="./css/index.scss"></style>
