<template>
  <div
    v-report:exposure="{ operid: '1627000010203' }"
    class="p-setting-logoff has-header-nav"
  >
    <post-header
      :menu-status="false"
      :auto-back="true"
      class="logoff-header"
    >
      账号注销
    </post-header>
    <div class="logoff-main">
      <div class="verify-text">
        <p class="no-indent">
          尊敬的用户您好：
        </p>
        <p>
          当前要注销的是您在腾讯游戏社区，微信登录的全平台账号（包括但不限于安卓，iOS等平台），请确认账号所属平台正确无误。
        </p>
      </div>
      <div class="verify-field">
        <div class="prefix">
          手机号
        </div>
        <input
          v-model="phone"
          @keyup="phoneInput"
          placeholder="请输入11位手机号"
          class="input"
          type="tel"
          maxlength="11"
        >
        <div class="get-verify-code" />
      </div>
      <div class="verify-field">
        <div class="prefix">
          验证码
        </div>
        <input
          v-model="verifyCode"
          @input="codeInput"
          placeholder="请输入验证码"
          class="input"
          type="tel"
          maxlength="6"
        >
        <div
          v-if="!isGettingCode"
          @click="getVerifyCode"
          class="get-verify-code"
        >
          获取验证码
        </div>
        <div
          v-if="isGettingCode"
          class="get-verify-code"
          style="color: #CCCCCC;"
        >
          重新获取({{ countNumber }})
        </div>
      </div>
      <Button
        :type="isActive ? 'primary' : 'default'"
        :disabled="!isActive"
        @click="logoff"
        class="start-logoff"
        size="xl"
      >
        开始注销
      </Button>
    </div>
    <Toast v-show="showToast">
      {{ toastText }}
    </Toast>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/ui/button/index.vue';
import Toast from '@/ui/toast/index.vue';
import { isMobile } from '@/util/validate';
import userApi from '@/api/user';
import PostHeader from '@/component/post-header/index.vue';

export default {
  name: 'LogoffVerify',
  components: {
    Button,
    Toast,
    PostHeader,
  },
  data() {
    return {
      isActive: false,
      phone: '',
      verifyCode: '',
      isGettingCode: false,
      countNumber: 60,
      showToast: false,
      toastText: '',
      countryCode: '86',
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    phone() {
      this.isActive = this.verify();
    },
    verifyCode() {
      this.isActive = this.verify();
    },
  },
  created() {
    this.$store.dispatch('user/getUserInfo');
  },

  methods: {
    /**
       * 手机号码按键事件
       * */
    phoneInput() {
      if (this.phone.length >= 11) {
        this.phone = this.phone.substring(0, 11);
      }
      this.phone = this.phone.replace(/[^\d]/, '');
    },
    /**
       * 验证码按键事件
       * */
    codeInput() {
      if (this.verifyCode.length >= 6) {
        this.verifyCode = this.verifyCode.substring(0, 6);
      }
      this.verifyCode = this.verifyCode.replace(/[^\d]/, '');
    },
    /**
       * 获取验证码
       * */
    getVerifyCode() {
      if (this.phone.length === 11 && isMobile(this.phone)) {
        this.countNumber = 60;
        this.isGettingCode = true;
        this.countDown();
        this.sendCaptcha();
      } else if (this.phone === '') {
        this.toast('请先输入手机号');
      } else {
        this.toast('手机号码有误，请重新输入');
      }
    },
    /**
       * 倒计时
       * */
    countDown() {
      if (this.countNumber === 0) {
        this.isGettingCode = false;
        clearTimeout(this.countTimer);
        return;
      }
      this.countNumber -= 1;
      this.countTimer = setTimeout(() => {
        this.countDown();
      }, 1000);
    },
    /**
       * 手机及验证码校验
       * @return {Boolean} 验证结果
       * */
    verify() {
      return this.phone.length === 11 && this.verifyCode.length === 6;
    },
    logoff() {
      if (this.verify()) {
        if (!isMobile(this.phone)) {
          this.toast('手机号格式不正确');
        } else {
          userApi.closeUserAccount({
            captcha: this.verifyCode,
            // eslint-disable-next-line no-shadow
          }).then((res) => {
            if (res?.ret === 0) {
              // 跳转到注销成功页
              this.$router.replace(`/setting/logoff-success?closeTime=${res.closeTime}`);
            } else {
              this.toast(`系统繁忙，请稍候重试(${res?.ret})`);
            }
          })
            .catch((e) => {
              if (e?.data?.ret === 20002) {
                this.toast('验证码不匹配，请重新输入');
                return;
              }
              if (e?.data?.ret === 21033) {
                this.toast('请输入绑定的手机号');
                return;
              }
              this.toast('系统繁忙，请稍候重试');
            });
        }
      } else {
        this.toast('手机号或验证码输入有误');
      }
    },
    toast(text) {
      this.toastText = text;
      this.showToast = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.showToast = false;
          resolve();
        }, 1500);
      });
    },
    sendCaptcha() {
      if (!this?.userInfo?.uid) {
        this.toast('请先登录');
        return;
      }
      userApi.sendCaptcha({
        uid: this.userInfo && this.userInfo.uid,
        phone: this.phone,
        countryCode: this.countryCode,
      }).then(() => {
        this.toast('验证码已发送，请注意查收');
      })
        .catch(() => {
          this.toast('系统繁忙，请稍候重试');
        });
    },
  },
};


</script>

<style lang="scss" src="./css/index.scss"></style>
