<template>
  <div
    v-report:exposure="{ operid: '1627000010205' }"
    class="p-setting-logoff has-header-nav"
  >
    <post-header
      :menu-status="false"
      :auto-back="true"
      class="logoff-header"
    >
      账号注销
    </post-header>
    <div class="logoff-main">
      <div class="reason">
        <img
          class="reason-img"
          src="./img/icon_100_successed.png"
        >
        <h3 class="condition-text">
          账号注销申请提交成功
        </h3>
        <div class="logoff-support">
          感谢您对腾讯游戏社区的支持
        </div>
      </div>
      <p style="font-size: 14px;">
        您的账号已于<span style="color:#4166b0;">&nbsp;{{ logoffTime }}&nbsp;</span>完成注销条件审核，成功提交注销申请。
        除法律法规规定和注销协议约定情况外，我们将在您提交注销申请之日起的<span style="color:#4166b0;">&nbsp;15&nbsp;</span>日内完成注销。
      </p>
      <p class="tips">
        在前述15日届满前，请您不要登录和使用账号，以确保注销的顺利完成，否则视为您撤销注销申请
      </p>
    </div>
    <div class="know-box">
      <Button
        @click="complete"
        size="xl"
        type="primary"
      >
        完成
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/ui/button/index.vue';
// import userApi from '@/api/user';
import dayjs from 'dayjs';
import jsBridge, { deleteAccount } from '@/jsbridge';
import PostHeader from '@/component/post-header/index.vue';

export default {
  name: 'Logoff',
  components: {
    Button,
    PostHeader,
  },
  data() {
    return {
      logoffTime: '0000-00-00 00:00',
    };
  },
  created() {
    const closeTime = Number(this.$route.query.closeTime) || Math.floor(+new Date() / 1000);
    this.logoffTime = dayjs(closeTime * 1000).format('YYYY-MM-DD HH:mm');
    // 返回键添加客户端调用
    jsBridge.ui.setBackListener({
      action: 1, // 增加监听
      callback: () => {
        deleteAccount();
      },
    });
  },
  methods: {
    complete() {
      deleteAccount();
    },
  },
};


</script>

<style lang="scss" src="./css/index.scss"></style>
