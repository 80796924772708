<template>
  <div
    v-report:exposure="{ operid: '1627000010201' }"
    class="p-setting-logoff has-header-nav"
  >
    <post-header
      :menu-status="false"
      :auto-back="true"
      class="logoff-header"
    >
      社区账号注销协议
    </post-header>
    <div class="logoff-main">
      <p style="margin-top: 9px;">
        尊敬的用户，在您正式开始下一步有关本产品的账号注销流程前，请您务必仔细阅读和充分理解本<a
          @click="openProtocol"
          style="color: #4166B0;text-decoration:underline;"
          href="javascript:void(0);"
        ><strong>《账号注销协议》</strong></a>（以下统称“本协议”）。
      </p>
      <p>
        您按照我们的注销操作流程开始注销流程的，或者您勾选本注销协议并开始下一步操作的，均视为您已经同意和遵守本协议全部内容。我们在此特别提示您：
      </p>
      <p>
        注销本产品账号后，除法律法规和本协议另有规定外，您该账号下的
        <strong>个人信息将会被删除或匿名化处理，您无法再检索、访问、获取继续使用和找回，也无权要求我们找回个人信息</strong>,
        前述个人信息包括但不限于：头像、昵称、社区积分、抽奖获得的虚拟道具或虚拟物品、发言、地区、聊天记录等内容。
      </p>
      <p>
        有关账号注销的其他详细规则及注意事项请点击查看<a
          @click="openProtocol"
          style="color: #4166B0;text-decoration:underline;"
          href="javascript:void(0);"
        ><strong>《账号注销协议》</strong></a>。
      </p>
    </div>
    <div
      class="next-box"
      style="height: 134px;"
    >
      <div class="select-field">
        <div
          :class="{active: isActive}"
          @click="toggleSelect"
          class="selected"
        />
        <p>
          我已详细阅读并同意<a
            @click="openProtocol"
            style="color: #4166B0;text-decoration:underline;"
            href="javascript:void(0);"
          ><strong>《账号注销协议》</strong></a>
        </p>
      </div>
      <Button
        :type="isActive ? 'primary' : 'default'"
        :disabled="!isActive"
        @click="next"
        size="xl"
        style="padding: 7px;"
      >
        下一步
      </Button>
    </div>
    <Dialog
      v-report:exposure="{ operid: '1627000010204' }"
      :show="isShowDialog"
      :confirm="true"
      :confirm-text-style="{color: '#4166B0'}"
      :confirm-text="'确定'"
      @confirm="logoff"
      @cancel="cancelLogoff"
    >
      <h3 class="dialog-title">
        《注销须知》
      </h3>
      <div style="max-height: 352px; overflow-y: scroll;">
        <p
          class="dialog-content"
          style="text-indent: 28px;"
        >
          为防止误操作，请再次确认是否注销账号并确认注销后的影响。该影响包括不限于以下内容，具体详见<a
            @click="openProtocol"
            style="text-decoration:underline;color:#4166B0;"
            href="javascript:void(0);"
          ><strong>《账号注销协议》</strong>
          </a>：
        </p>
        <p
          style="font-size: 14px;"
          class="dialog-content"
        >
          1.该账号一旦注销，您将无法登录、使用该账号，且无法恢复。除法律法规或账号注销协议另有规定外。<strong>您该账号下的个人信息将会被删除或匿名化处理。您无法再找回您的个人信息，也无权要求我们找回</strong>。
        </p>
        <p
          style="margin-bottom: 0;"
          class="dialog-content"
        >
          2.<strong>该账号在本产品使用期间已产生及未来可能产生的所有收益或权益将会清除</strong>。
          据此，也特别提示您，在您注销本账号前请您确认已妥善处理您的收益或权益。否则，
          <strong>账号注销后，我们有权对该账号下的全部收益或权益做清除处理，因此产生的后果由您自行承担</strong>。
          前述收益或权益包括但不限于账号的社区积分、道具、圈主收益、答主收益、等级权益等收益。
        </p>
      </div>
    </Dialog>
    <Toast v-show="showToast">
      {{ toastText }}
    </Toast>
  </div>
</template>

<script>
import Button from '@/ui/button';
import Dialog from '@/ui/dialog/index.vue';
import Toast from '@/ui/toast/index.vue';
import { openUrl } from '@/jsbridge';
import userApi from '@/api/user';
import reportApi from '@/api/report';
import PostHeader from '@/component/post-header/index.vue';

export default {
  name: 'LogoffProtocol',
  components: {
    Button,
    Dialog,
    Toast,
    PostHeader,
  },
  data() {
    return {
      isActive: false,
      isShowDialog: false,
      showToast: false,
      toastText: '',
    };
  },
  methods: {
    toggleSelect() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        reportApi({ operid: '1627000010302' }, '账号注销协议页面“下一步”点击');
      }
    },
    next() {
      this.isShowDialog = true;
      reportApi({ operid: '1627000010303' }, '账号注销协议页面“下一步”点击');
    },
    openProtocol() {
      openUrl({
        url: 'https://game.qq.com/account_cancellation_agreement.shtml',
        target: 1,
      });
    },
    cancelLogoff() {
      this.isShowDialog = false;
      reportApi({ operid: '1627000010305' }, '账号注销须知弹框点击取消');
    },
    toast(text) {
      this.toastText = text;
      this.showToast = true;
      return new Promise((resolve) => {
        setTimeout(() => {
          this.showToast = false;
          resolve();
        }, 1500);
      });
    },
    logoff() {
      reportApi({ operid: '1627000010304' }, '账号注销须知弹框点击取消');
      userApi.getUserLoginInfo({
        uid: this?.userInfo?.uid,
      })
        .then((res) => {
          this.isShowDialog = false;
          if (res.ret === 0) {
            if (!res?.userInfo?.phone) {
              userApi.closeUserAccount({
                captcha: '',
                // eslint-disable-next-line no-shadow
              }).then((res) => {
                if (res?.ret === 0) {
                  // 跳转到注销成功页
                  this.$router.replace(`/setting/logoff-success?closeTime=${res.closeTime}`);
                } else {
                  this.toast(`系统繁忙，请稍候重试(${res?.ret})`);
                }
              })
                .catch((e) => {
                  this.toast(`系统繁忙，请稍候重试(${e?.data?.ret})`);
                });
            } else {
              this.$router.replace('/setting/logoff-verify');
              // openUrl({
              //   url: `${window.location.origin}/setting/logoff-verify`,
              //   target: 1,
              // });
            }
          } else {
            this.toast('系统繁忙，请稍候重试');
          }
        })
        .catch(() => {
          this.isShowDialog = false;
          this.toast('系统繁忙，请稍候重试');
        });
    },
  },
};


</script>

<style lang="scss" src="./css/index.scss"></style>
