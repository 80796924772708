<template>
  <div
    v-report:exposure="{ operid: '1627000010202' }"
    class="p-setting-logoff has-header-nav"
  >
    <post-header
      :menu-status="false"
      :auto-back="true"
      class="logoff-header"
    >
      账号注销
    </post-header>
    <div class="logoff-main">
      <div class="reason">
        <img
          class="reason-img"
          src="./img/icon_100_failed.png"
        >
        <h3 class="condition-text">
          当前账号不满足注销条件
        </h3>
      </div>
      <p>
        亲爱的用户，经检测，您的账号不满足注销条件，您的账号是经过官方身份认证的认证号，请先解除身份认证后再尝试注销申请。
      </p>
      <p class="intro">
        您可以通过私聊「闪闪」社区官方账号解除您当前的身份认证
      </p>
    </div>
    <img
      class="intro-img"
      src="./img/intro_img.png"
    >
    <div class="know-box">
      <Button
        @click="confirm"
        size="xl"
        type="primary"
      >
        知道了
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/ui/button';
import { popBack } from '@/jsbridge';
import PostHeader from '@/component/post-header/index.vue';

export default {
  name: 'Logoff',
  components: {
    Button,
    PostHeader,
  },
  methods: {
    confirm() {
      // this.$router.replace('/setting/logoff');
      popBack();
    },
  },
};


</script>

<style lang="scss" src="./css/index.scss"></style>
